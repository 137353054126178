/* Estilos generales */
.App {
  font-family: "Inter", sans-serif;
}

.main-content {
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

h2 {
  margin: 0;
  padding: 0;
}

.logo {
  height: 54px;
  width: 54px;
  float: left;
  margin-left: 40px;
}

.titleWebsite {
  margin: 20px;
}

/* Estilos para el encabezado */
header {
  background-color: #0c084c;
  color: black;
  height: 76px;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
}

/* Estilos para la barra de navegación */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
}

.navbarLis {
  position: absolute;
  right: 40px;
}

nav ul li {
  display: inline;
  margin-left: 60px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.news-sections {
  flex: 2;
  margin-right: 20px;
}

.news-section h2 {
  color: #333;
}

/* Estilos para las listas de noticias */
.news-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Estilos para los artículos de noticias */
.news-item {
  border-radius: 5px;
  padding: 10px;
}

.news-image {
  width: 100%;
  border-radius: 5px;
}

.news-title {
  margin-top: 10px;
}

.news-description {
  color: #666;
}

/* Estilos para el aside */
aside {
  flex: 1;
}

.ad {
  margin-bottom: 20px;
}

.ad img {
  max-width: 100%;
}

.topStory-image {
  position: relative;
  margin-top: 2%;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;

}


.topStory-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.294);
  padding: 20px;
}

.topStory-title {
  margin: 0;
  color: white;
}

.topStory-description {
  margin-top: 10px;
  color: white;
}

.view-all-button {
  color: black;
  border: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.newsXs-title {
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.publishedTime {
  font-weight: 50;
}

.website-list {
  list-style: none;
  padding: 0;
}

.website-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; /* Espacio entre grupos */
}

.website-group li {
  width: calc(50% - 20px); /* Ancho del 50% menos el espacio entre elementos */
  height: 160px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.website-group li a {
  text-decoration: none;
  color: #333;
}

.website-li {
  background-color: #0c084c;
}
.otherwebsiteimg {
  width: 60%;
  margin-left: 20%;
  height: 60%;
}

.otherwebsiteimgp2 {
  width: 100%;
  height: 180px;
  border: none;
  margin-left: -px;

}

.l0{
  margin-bottom: 20px !important;
}

.layer0logo {
  margin-top: 20px;
  width: 35%;
}

.layer1logo {
  margin-top: 40px;
  margin-bottom: 15px;
}

.textCardMoreWebsites {
  color: white;
  text-align: center;
  font-size: 25px;
  margin-top: 1px;
}

.newsRestContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.image {
  width: 40%;
  height: 240px;
  
}

.contentNews {
  width: 60%;
  padding: 10px;
}

.newsRestTitle {
  margin: 0;
  font-size: 1.5rem;
  text-decoration: none;
}

#uniqueTitleId {
  text-decoration: none !important;
}

.newsRestDescription {
  margin-top: 5px;
  font-size: 1rem;
}

.layer1div {
  height: 170px;
  position: relative;
  text-align: center;
}

.layer0div {
  height: 170px;
  position: relative;
  text-align: center;
}

.dots-loader {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dots-loader > div {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  margin: 0 5px;
  animation: dots-loader-animation 1.5s infinite ease-in-out;
}

@keyframes dots-loader-animation {
  0%,
  20% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.article-container {
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
}

.article-content {
  flex: 0 0 70%;
  margin-right: 2%;
}

.article-image {
  width: 100%;
  height: 500px;
}


.article-details {
  margin-top: 20px;
}

.published-date {
  font-weight: 150;
}

.more-news-aside {
  flex: 0 0 30%;
  margin-top: -25px;
}

.ad1container{
  text-align: center;
}

.ad1{
  height: 20%;
  width: 50%;
}

.ad2container{
  text-align: center;
}

.ad2{
  height: 20%;
  width: 100%;
  margin-bottom: 30px;
}

.ad3container{
  text-align: center;
}

.ad3{
  height: 20%;
  width: 100%;

}


/* responsive STYLES. */

@media screen and (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .news-sections {
    margin-right: 0;
    margin-bottom: 20px; /* Espacio entre los componentes en dispositivos móviles */
  }

  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;
    align-items: center;
    align-content: center;
    text-align: center;
  }
  .logo {
    margin-left: 300%;
  }
  .topStory-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.294);
    padding: 3px;
  }

  h2 {
    font-size: 1.1em;
  }

  .article-image {
    width: 129%;
    height: 200px;
    margin-left: -40px;
    margin-top: -39.6px;
  }

 

  .topStory-description {
    margin-top: 10px;
    color: white;
    font-size: 0.6em;
  }

  .article-container {
    flex-direction: column;
  }

  .article-content {
    flex: 1 1 100%;
    margin-right: 0;
  }

  .more-news-aside {
    flex: 1 1 100%;
    margin-top: 20px;
  }

  .newsRestTitle{
    margin: 0;
    font-size: 0.8rem;
    text-decoration: none;
  }
  .publishedTime{
    font-size: 10px;
  }
  .image{
    height: 140px;
  }
}

@media screen and (max-width: 480px) {
  .news-sections {
    min-width: 100%; /* Permitir que los componentes se expandan completamente en dispositivos móviles */
  }
}
